<template>
  <v-main align="center">
    <v-container class="fill-height" fluid v-if="hasCompany && !status.loading">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-card class="elevation-12">
            <TagToolbar :title="$t('tag.name')" />
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import store from "@/stores/store";
import i18n from "@/languages";
import TagToolbar from "@/components/tag/TagToolbar";

export default {
  name: "Tag",
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
  },
  methods: {},
  created() {
    if (!this.hasCompany) {
      this.$router.push("/login");
      store.dispatch(
        "alert/error",
        i18n.t("application.noCompanyError", { root: true })
      );
    }
  },
  components: {
    TagToolbar,
  },
};
</script>

<style>
</style>